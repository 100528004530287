<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('settings.changeLanguage') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" sm="12">
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('settings.changeLocaleExplanation1') }}
					</p>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-form ref="form" v-model="valid">
						<v-select
							v-model="locale"
							:items="availableLanguages"
							:label="$t('auth.language')"
							:rules="rules.locale"
							required
							item-text="name"
							item-value="key"
							return-object
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							prepend-inner-icon="mdi-translate"
							style="max-width: 400px"
						/>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn x-large rounded class="px-4" color="primary" @click="callChangeLocale()">
				{{ $t('settings.changeLanguage') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsChangeLocaleForm',
	props: {
		currentLocale: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			valid: false,
			locale: null,
			rules: {
				locale: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableLanguages.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported'),
				],
			},
		}
	},
	computed: {
		...mapGetters({
			availableLanguages: 'data/availableLanguages',
		}),
	},
	created() {
		this.fetchAvailableLanguages()
		this.locale = this.currentLocale
	},
	methods: {
		callChangeLocale() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeLocale(this.locale)
		},
		...mapActions('data', ['fetchAvailableLanguages']),
		...mapActions('settings', ['changeLocale']),
	},
}
</script>
